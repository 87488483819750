import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import AccountDeletion from 'views/application/users/account-profile/Profile3/AccountDeletion';
import DemoRequest from 'views/landing/Host/DemoRequest';
import { Grid, Link, Typography } from '@mui/material';

// dashboard routing

// widget routing

// application - user social & account profile routing

// application - user cards & list variant routing

// application - customer routing

// application routing

// application e-commerce pages
const AppECommProducts = Loadable(lazy(() => import('views/application/e-commerce/Products')));
const AppECommProductDetails = Loadable(lazy(() => import('views/application/e-commerce/ProductDetails')));
const AppECommCheckout = Loadable(lazy(() => import('views/application/e-commerce/Checkout')));
const HostLanding = Loadable(lazy(() => import('views/landing/Host')));
const ClubPage = Loadable(lazy(() => import('views/clubs/Club')));

// forms component routing

// forms plugins layout

// forms plugins routing

// table routing

// forms validation

// chart routing

// basic ui-elements routing

// advance ui-elements routing

// pricing page routing

// utilities routing

// sample page routing

// ==============================|| MAIN ROUTING ||============================== //
const AmazonRoutes = () => (
    <Grid sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Link href="https://amzn.to/3ABbJ3j"> Pre Workout</Link>
        <Link href="https://amzn.to/4f6u3AD"> Diapers</Link>
        <Link href="https://amzn.to/3Cbftcq"> Velvet Ribbon Spool</Link>
    </Grid>
);

const BothRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        { path: '/', element: <AppECommProducts /> },

        {
            path: '/comp/:type',
            element: <AppECommProducts />
        },

        {
            path: '/host/overview',
            element: <HostLanding />
        },
        {
            path: '/demo/request',
            element: <DemoRequest />
        },

        {
            path: '/e/:type/:slug',
            element: <AppECommProductDetails />
        },
        {
            path: '/amazon-affiliate',
            element: <AmazonRoutes />
        },
        {
            path: '/e/:type/:slug-:id',
            element: <AppECommProductDetails />
        },
        {
            path: '/comp/:type/details/:id',
            element: <AppECommProductDetails />
        },

        {
            path: '/tournament/details/:id',
            element: <AppECommProductDetails />
        },

        {
            path: '/checkout',
            element: <AppECommCheckout />
        },

        {
            path: '/account-deletion',
            element: <AccountDeletion />
        }
        // {
        //     path: '/c/:clubId',
        //     element: <ClubPage />
        // }
    ]
};

export default BothRoutes;
