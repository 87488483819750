/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';

// material-ui
import { Grid, Typography, Table, TableBody, TableCell, TableRow, TableContainer, Button } from '@mui/material';

// assets
import { makeStyles } from '@mui/styles';
import AnimateButton from 'ui-component/extended/AnimateButton';

// project imports
import PlayerCell from 'views/components/playerCell.component';
import { WindowContext } from 'contexts/WindowContext';
import getPointDifferentialPercentage from 'utils/pointDifferentialPercentage';
import PoolsTableHeader from './poolsTableHeader';

const PoolMatchTable = ({ teams, status, handleReplaceDrawerOpen, isAdmin, setReplacedUser }) => {
    const { isMobile } = useContext(WindowContext);
    const useStyles = makeStyles({
        tableCell: {
            borderRight: '1px solid #e0e0e0'
        },
        chipCustom: {
            '& .MuiChip-label': { fontSize: isMobile ? 12 : 13 } // sub-selector
        }
    });
    const onClickSubButton = (user) => {
        handleReplaceDrawerOpen();
        setReplacedUser(user);
    };

    const styles = useStyles();

    function renderPlayers(team) {
        return team.team ? ( // is often just one player and team is not populated
            team.team?.players.map((player) => <PlayerCell user={player?.user} isSub={player?.is_sub} isAbsent={player?.absent} />)
        ) : (
            <PlayerCell user={team?.user} isSub={team?.is_sub} isAbsent={team?.absent} />
        );
    }

    function renderMatchTeamRow(team, teamIndex, poolStatus) {
        // don't bother rendering if player was a sub, then withdrew.
        if (!isAdmin && team?.is_sub && team?.absent) {
            return <></>;
        }
        return (
            <TableRow key={team.id}>
                <TableCell width={85} className={styles.tableCell} align="center" sx={{ maxWidth: '45px' }}>
                    <Typography variant="subtitle1">{team.rank ? team.rank : teamIndex + 1}</Typography>
                </TableCell>
                <TableCell
                    sx={{
                        flexDirection: 'column',
                        minWidth: '225px',
                        whiteSpace: 'nowrap'
                    }}
                    className={styles.tableCell}
                >
                    {(poolStatus === 'completed' || !isAdmin) && renderPlayers(team)}
                    {isAdmin && poolStatus !== 'completed' && (
                        <Grid
                            container
                            sx={{
                                whiteSpace: 'nowrap',
                                minWidth: '150px'
                            }}
                        >
                            <Grid item xs={8}>
                                {renderPlayers(team)}
                            </Grid>
                            <Grid container item xs={4}>
                                <Grid item xs={12}>
                                    <AnimateButton>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="error"
                                            onClick={() => onClickSubButton(team?.user)}
                                            sx={{ mt: 1 }}
                                        >
                                            Sub
                                        </Button>
                                    </AnimateButton>
                                </Grid>
                                {/* <Grid item xs={4}>
                                    withdrawn
                                </Grid>
                                <Grid item xs={4}>
                                    delete
                                </Grid> */}
                            </Grid>
                        </Grid>
                    )}
                </TableCell>
                <TableCell className={styles.tableCell} align="center">
                    <Grid container justifyContent="center">
                        <Typography alignSelf="flex-end">{team.won}</Typography>
                        {team.head_to_head_wins !== null && team.head_to_head_wins !== undefined && (
                            <Grid
                                item
                                sx={{ backgroundColor: 'secondary.dark', paddingX: 0.75, paddingY: 0.25, borderRadius: 0.5 }}
                                ml={0.25}
                                mb={2}
                            >
                                <Typography color="white" variant="h6" fontSize={10}>
                                    {team.head_to_head_wins}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </TableCell>
                <TableCell className={styles.tableCell} align="center">
                    {team.lost}
                </TableCell>
                <TableCell className={styles.tableCell} align="center">
                    {team.points_earned}
                </TableCell>
                <TableCell className={styles.tableCell} align="center">
                    {team.points_against}
                </TableCell>
                <TableCell className={styles.tableCell} align="center">
                    {team.point_differential}
                </TableCell>
                <TableCell className={styles.tableCell} align="center">
                    {getPointDifferentialPercentage(team.points_earned, team.points_against)}
                </TableCell>
            </TableRow>
        );
    }

    function sortResults(a, b) {
        if (a.won === 0 && a.lost === 0 && b.won === 0 && b.lost === 0) {
            return 0;
        }
        const A_WINS = -1;
        const B_WINS = 1;
        if (a.won === 0 && a.lost === 0) {
            return B_WINS;
        }
        if (b.won === 0 && b.lost === 0) {
            return A_WINS;
        }

        const aWinLoss = a.won - a.lost;
        const bWinLoss = b.won - b.lost;
        if (aWinLoss > bWinLoss) {
            return A_WINS;
        }
        if (bWinLoss > aWinLoss) {
            return B_WINS;
        }

        const aPointDiff = a.points_earned - a.points_against;
        const bPointDiff = b.points_earned - b.points_against;

        if (aPointDiff > bPointDiff) {
            return A_WINS;
        }
        if (bPointDiff > aPointDiff) {
            return B_WINS;
        }

        return 0;
    }

    teams.sort(sortResults);
    return (
        <TableContainer>
            <Table size="small" sx={{ backgroundColor: 'white' }}>
                <PoolsTableHeader teams={teams} />
                <TableBody>{teams?.map((team, teamIndex) => renderMatchTeamRow(team, teamIndex, status))}</TableBody>
            </Table>
        </TableContainer>
    );
};

export default PoolMatchTable;
