/* eslint-disable prettier/prettier */
import React, { useState } from 'react';

// material-ui
import { Grid } from '@mui/material';

// assets
import capitalizeWords from 'utils/capitalizeWords';

// project imports
import ReplaceUserDrawer from 'views/admin/AdminHub/EventInfo/components/ReplaceUserDrawer';
import PoolsTitleBar from './poolsTitleBar';
import PoolMatchTable from './poolMatchTable';

const PoolsTable = ({ eventPools, setSelectedPool, poolAction, isAdmin }) => {
    const [openReplaceUserSidebar, setOpenReplaceUserSidebar] = useState(false);
    const [replacedUser, setReplacedUser] = useState();

    const handleReplaceDrawerOpen = () => {
        setOpenReplaceUserSidebar((prev) => !prev);
    };

    return eventPools?.map((pool) => {
        const categoryName = capitalizeWords(pool.name);
        const teams = pool.pool_teams;

        return (
            <Grid container key={pool.id} data-test-id="views/components/poolsTable.component">
                <PoolsTitleBar categoryName={categoryName} pool={pool} poolAction={poolAction} setSelectedPool={setSelectedPool} />
                <PoolMatchTable
                    teams={teams}
                    status={pool?.status}
                    handleReplaceDrawerOpen={handleReplaceDrawerOpen}
                    setReplacedUser={setReplacedUser}
                    isAdmin={isAdmin}
                />
                <ReplaceUserDrawer
                    openUserSidebar={openReplaceUserSidebar}
                    handleDrawerOpen={handleReplaceDrawerOpen}
                    leagueId={eventPools && eventPools[0]?.league_id}
                    existingUsers={[]}
                    replacedUser={replacedUser}
                    isSub
                    invisibleBg
                    eventId={eventPools && eventPools[0]?.event_instance_id}
                />
            </Grid>
        );
    });
};

export default PoolsTable;
